<template>
  <div class="gift-info-box">
    <div class="gift_box">
      <div v-if ="giftinfo.gift_source == 1" v-show="isSHOW" class="gift_top">
        <div class="product_left">
          <!-- 商品大图 -->
          <div class="product_big_img">
            <img :src="giftinfo.img" alt="">
          </div>
        </div>
        <div class="product_right">
          <!-- 商品名称 -->
          <div class="product_name">{{ gift_name }}</div>
          <div class="product_activity">
            <!-- 商品白给 -->
            <div v-if="true" class="price_box">
              <!-- 无拼团折扣时样式 -->
              <div class="price_left no_discount">
                <div class="price_top">
                  <!-- 商品价格 -->
                  <div class="title">积 分</div>
                  <div class="selling_price">
                    <span class="unit"><img src="@/assets/image/doble-h.png" alt=""></span>
                    <span class="money number">{{ productPrice }}</span>
                  </div>
                </div>
              </div>
              <!-- 右侧购买信息 -->
              <div class="price_right">
                <div class="line"></div>
                <div class="buy_data">
                  <p class="evaluate bug_count">已兑<span class="red mlr2 number"> {{ giftinfo.sale_num }}</span></p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="spec.length" class="product_info">
            <!-- 商品规格 -->
            <div v-for="(item,index) in spec" :key="index" class="specification">
              <div class="title">{{ item.spec_name }}</div>
              <div class="specification_list">
                <div v-for="(val,key) in item.spec_value" :key="key" class="specification_item" @click="chooseSpecification(item,index,val,key)">
                  <!-- <div class="product_status">缺货</div> -->
                  <div class="item" :class="[defaultProductId[index]==val.spec_value_id?'on':'',index==val.disablePosition&&val.spec_value_id==val.disableSpecificationId?'disable':'']">
                    <!-- <img :src="item.thumb" alt=""> -->
                    <span>{{ val.spec_value_name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- 商品数量 -->
            <div class="product_num">
              <div class="title">数量</div>
              <el-input-number v-model="num" disabled size="small" :min="1" :max="1" @change="handleChange"></el-input-number>
              <span class="red astrict">限购1件</span>
            </div>
            <!-- 立即购买 -->
            <div class="buy_box">
              <div class="go_buy" @click="RedeemNow">立即兑换</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if ="giftinfo.gift_source == 2" v-show="isSHOW" class="gift_top">
        <div class="gift_img">
          <img :src="giftinfo.img" alt="">
        </div>
        <div class="gift_desc">
          <el-tooltip class="item" effect="dark" :content="giftinfo.gift_name" placement="left-start">
            <h4>{{ giftinfo.gift_name }}</h4>
          </el-tooltip>
          <div class="desc">
            <span>
              <img src="@/assets/image/zlhl.png" alt="">
              {{ giftinfo.quantity }}
            </span>
            <span> 已兑{{ giftinfo.sale_num }}</span>
          </div>
          <p v-if="giftinfo.gift_source === 1">
            {{ giftinfo.info.introduce }}
          </p>
          <div class="num_line">
            <div>
              <el-button
                      type="text"
                      style="width:20px;font-size:18px;"
                      :disabled="true"
                      @click="reduce"
              >-</el-button>
              <el-input
                      v-model="num"
                      type="text"
                      style="width:70px;height:35px;"
                      :disabled="true"
              ></el-input>
              <el-button
                      type="text"
                      style="width:20px;font-size:18px;"
                      :disabled="true"
                      @click="add"
              >+</el-button>
            </div>
            <span style="color:#ee2e2e">限购{{ giftinfo.purchase_num }}件</span>
            <span>剩余{{ giftinfo.num }}件</span>
          </div>
          <div class="dh_line">
            <span style="margin-right:10px;">
              <img src="@/assets/image/zlhl.png" alt="" class="ljdh">
              {{ giftinfo.quantity * num }}
            </span>
            <el-button type="primary" @click="RedeemNow">立即兑换</el-button>
          </div>
        </div>
      </div>
      <!-- 产品详情 -->
      <div class="gift_img_box">
        <div class="gift_img_title">
          <img src="@/assets/image/redline.png">
          <div>产品详情</div>
        </div>
        <div v-if="giftinfo.gift_source === 1" class="gifi_img_content">
          <div v-for="(item, index) in giftimg" :key="index">
            <img :src="item" alt="">
          </div>
        </div>
        <div v-else-if="giftinfo.gift_source === 2" class="gifi_img_content">
          <div style="margin-bottom:15px;" v-html="giftinfo.info.param"></div>
          <div v-html="giftinfo.info.introduction"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { giftinfo, getMyScore } from '@/api/giftinfo.js'
import { getRow } from '@/api/mine.js'
import { math } from '@/utils/math.js'
export default {
  data() {
    return {
      num: 1,
      giftinfo: {},
      giftimg: [],
      isSHOW: false,
      tips: '',
      gift_name: '',
      gift_source: 1, // 区分京东还是原创  1是原创 2是京东

      productPrice: 0, // 产品价格
      lessonSpecPrice: 0, // 产品单价
      spec: [], // 商品规格
      spec_sku: [], // 规格对应信息
      maxNum: 100, // 最大可购数量
      // 默认选中有库存规格的产品id
      defaultProductId: [],
      check_lesson_sku_str: this.$route.query.sku_id || '', // 选中规格的产品sku
      check_lesson_sku_id: this.$route.query.spec_sku_id || '', // 选中规格的产品sku 的id
      // 克隆的规格及sku列表用于重置数据
      copy_spec_list: [],
      copy_spec_sku: []
    }
  },
  mounted() {
    var banner = document.getElementById('banner')
    banner.style.display = 'none'
    this.getgiftinfo()
    this.getRow()
  },
  methods: {

    // 选择规格
    chooseSpecification(item, index, val, key) {
      console.log(item, index, val, key, '切换规格')
      // 如果没有在禁用状态下
      if (!val.disablePosition && val.disablePosition != 0) {
        this.maxNum = 100// 重置可购数量
        // // 高亮显示产品规格
        this.defaultProductId[index] = val.spec_value_id.toString()
        // console.log(this.defaultProductId)
        this.getCorrelation(this.defaultProductId, JSON.parse(JSON.stringify(this.copy_spec_sku)))
        // this.getProductInfo(val.spec_sku_id)
        // 查找所选规格对应的sku信息
        var productItem = this.getSpecInfo(this.defaultProductId.join('_'))
        // 获取产品价格折扣等信息
        // this.getProductInfo(productItem.spec_sku_id)
        this.getProductInfo(productItem)
      }
    },
    // 根据所选规格查找对应产品规格信息
    getSpecInfo(spec) { // 传入规格值 1_5_7
      for (let i = 0; i < this.spec_sku.length; i++) {
        if (this.spec_sku[i].sku_id == spec) {
          return this.spec_sku[i]
        }
      }
    },
    // 封装取当前线路相关的规格线路
    getCorrelation(checkId, allSku) { // 第一个参数为当前线路id数组 ，第二个为所有规格线路
      this.spec = JSON.parse(JSON.stringify(this.copy_spec_list))
      this.spec_sku = JSON.parse(JSON.stringify(this.copy_spec_sku))
      // console.log(checkId, allSku, '接收参数')
      for (let i = 0; i < allSku.length; i++) {
        allSku[i].intersection = this.intersection(allSku[i].spec_sku_id_arr, checkId)
        // 差集
        allSku[i].diff = this.diff(allSku[i].spec_sku_id_arr, checkId)
      }
      // console.log(allSku, 'allSkuallSku')
      // 找到跟当前规格线路相关且库存为0 的条目
      // 跟当前线路相关规格路线为只有一个规格不同的规格组合
      const correlationData = allSku.filter((item) => {
        return item.intersection.length == checkId.length - 1 && item.num == 0
      })
      // 确定需要禁用规格的按钮位置
      for (let i = 0; i < correlationData.length; i++) {
        // 添加规格位置，disablePosition为当前规格列表所在规格数组的下标
        // disableSpecificationId为当前禁用规格id 所在规格列表的下标
        correlationData[i].disablePosition = correlationData[i].spec_sku_id_arr.indexOf(correlationData[i].diff.join(''))
        correlationData[i].disableSpecificationId = correlationData[i].diff.join('')
      }
      // console.log(correlationData, 'correlationDatacorrelationData')
      // 把产品规格需要禁用的坐标放到渲染页面的规格列表中
      for (let i = 0; i < correlationData.length; i++) {
        // 循环规格列表
        for (let j = 0; j < this.spec.length; j++) {
          if (correlationData[i].disablePosition == j) {
            // 循环规格值
            for (let k = 0; k < this.spec[j].spec_value.length; k++) {
              if (correlationData[i].disableSpecificationId == this.spec[j].spec_value[k].spec_value_id) {
                this.spec[j].spec_value[k].disablePosition = correlationData[i].disablePosition
                this.spec[j].spec_value[k].disableSpecificationId = correlationData[i].disableSpecificationId
              } else {
                continue
              }
            }
          } else {
            continue
          }
        }
      }
      // console.log(correlationData, '含有库存为0的规格线路')
      // console.log(this.spec_sku)
      // console.log(this.spec)
    },
    // 获取商品价格等信息
    getProductInfo(productItem) {
      for (let i = 0; i < this.spec_sku.length; i++) {
        if (this.spec_sku[i].sku_id == productItem.sku_id) {
          this.productPrice = this.spec_sku[i].price
          this.lessonSpecPrice = this.spec_sku[i].price
          this.check_lesson_sku_id = this.spec_sku[i].spec_sku_id
        }
      }
      console.log(this.spec_sku, 'this.spec_sku')
      console.log(productItem, 'productItem')
      console.log(this.check_lesson_sku_id, 'check_lesson_sku_id')
      this.getProductPrice(Number(this.lessonSpecPrice), this.num)
    },
    // 找出商相同的规格id
    // [1,1,3]  [2,3,3] 返回 [3]
    intersection(arr1, arr2) {
      const common = []
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] === arr2[i]) {
          common.push(arr1[i])
        }
      }
      return common
    },
    // 找出商不同的规格id
    // [2,3,3] [2,3,2]返回[2]
    // [1,1,3] [2,3,3]返回[2,3]
    diff(arr1, arr2) {
      const common = []
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          common.push(arr1[i])
        }
      }
      return common
    },
    // 取出一条库存不为0的产品规格 （高亮显示id）
    getDefaultPro(arr) { // arr为产品规格详细列表
      for (let i = 0; i < arr.length; i++) {
      // 遍历产品规格列表，寻找产品规格中的库存不为0的产品规格 （高亮显示id） 并将其存储在变量中
        if (arr[i].num > 0 || arr.length === 1) {
          //  取当前规格的spec_sku_id默认选中商品，规格高亮
          return {
            defaultId: arr[i].sku_id.split('_'),
            spec_sku_id: arr[i].spec_sku_id,
            sku_id: arr[i].sku_id
          }
        }
      }
    },
    handleChange(num) {
      this.num = num
      this.getProductPrice(Number(this.lessonSpecPrice), this.num)
    },
    getProductPrice(lessonSpecPrice, num = 1) {
      this.productPrice = math.multiply(lessonSpecPrice, num)
    },

    reduce() {
      if (this.num === 0) {
        return
      } else {
        this.num--
      }
    },
    add() {
      if (this.num < this.giftinfo.num) {
        this.num++
      } else {
        this.num = this.giftinfo.num
        this.$alert('库存不足', '提示', {})
      }
    },
    getgiftinfo() {
      let id = ''
      if(this.$route.query.id){
        id = this.$route.query.id
      }else{
        id = this.$route.query.giftid
      }
      const info = {
        id: id,
        show: this.$route.query.show
      }
      giftinfo(info)
        .then((res) => {
          if (res.errNo == '99') {
            this.$alert(res.msg, '提示', {})
            return
          }
          if (res.errNo === 0) {
            this.isSHOW = true
          }
          if (res.result === '该商品已下架') {
            this.$alert('该商品已下架', '提示', {})
            return
          }
          if (res.result.img.slice(0, 4) !== 'http') {
            res.result.img = `http://img13.360buyimg.com/n12/${res.result.img}`
          }
          this.giftinfo = res.result
          this.giftimg = res.result.info.content
          this.gift_name = res.result.gift_name
          this.gift_source = res.result.gift_source

          this.num = res.result.purchase_num || 1
          console.log(this.num, 'nummm')
          if (this.gift_source === 1) {
            // 商品规格、套餐
            // this.productArr = data.product
            this.spec = res.result.spec
            // 各种规格对应的商品价格及库存
            this.spec_sku = res.result.spec_sku
            this.spec_sku.forEach((item) => {
              item.spec_sku_id_arr = item.sku_id.split('_')
              // item.spec_sku_id_sort = JSON.parse(JSON.stringify(item.spec_sku_id_arr)).sort()
            })
            // console.log('所有规格列表', this.spec_sku)
            // 深克隆一组数据方便重置选项列表
            this.copy_spec_list = JSON.parse(JSON.stringify(this.spec))
            // console.log(this.copy_spec_list, 'copy_spec_list')
            this.copy_spec_sku = JSON.parse(JSON.stringify(this.spec_sku))
            // console.log(this.copy_spec_sku, 'this.copy_spec_sku')
            // 如果没有选中 取出一条库存不为0的产品规格进行默认选中，否则高亮之前选中状态
            // 若之前高亮状态库存为0 则高亮库存不为0的产品规格
            if (!this.$route.query.spec_sku_id) {
              console.log(this.getDefaultPro(this.spec_sku).defaultId, '高亮条目')
              this.defaultProductId = this.getDefaultPro(this.spec_sku).defaultId
              this.check_lesson_sku_str = this.getDefaultPro(this.spec_sku).sku_id
              this.check_lesson_sku_id = this.getDefaultPro(this.spec_sku).spec_sku_id
              // 获取所选规格价格
              this.getProductInfo(this.getDefaultPro(this.spec_sku))
            } else {
              for (let i = 0; i < this.spec_sku.length; i++) {
                if (this.spec_sku[i].spec_sku_id == this.$route.query.spec_sku_id) {
                  // 如果之前选中没有库存 执行上面逻辑
                  if (this.spec_sku[i].num == 0) {
                    this.defaultProductId = this.getDefaultPro(this.spec_sku).defaultId
                    this.check_lesson_sku_str = this.getDefaultPro(this.spec_sku).sku_id
                    this.check_lesson_sku_id = this.getDefaultPro(this.spec_sku).spec_sku_id
                    // 获取所选规格价格
                    this.getProductInfo(this.getDefaultPro(this.spec_sku))
                  } else {
                    // 取之前高亮条目
                    this.defaultProductId = this.$route.query.sku_id.split('_')
                    this.check_lesson_sku_str = this.$route.query.sku_id
                    this.check_lesson_sku_id = this.$route.query.spec_sku_id
                    this.getProductInfo(this.$route.query.spec_sku_id)
                  }
                  break
                }
              }
            }
            // 取出所有跟当前线路相关的规格组合
            this.getCorrelation(this.defaultProductId, this.spec_sku)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 立即兑换
    RedeemNow() {
      if (this.giftinfo.gift_source == 2){
        if (this.giftinfo.num < 1) {
          return this.$alert('库存不足')
        }
      }else{
        var productItem = this.getSpecInfo(this.defaultProductId.join('_'))
        var num = ''
        for (let i = 0; i < this.spec_sku.length; i++) {
          if (this.spec_sku[i].sku_id == productItem.sku_id) {
            num = this.spec_sku[i].num
          }
        }
        if (num < 1) {
          return this.$alert('库存不足')
        }
      }
      getMyScore()
        .then((res) => {
          let id = ''
          if(this.$route.query.id){
            id = this.$route.query.id
          }else{
            id = this.$route.query.giftid
          }
          console.log(this.gift_source,"this.gift_source")
          console.log(this.giftinfo.quantity,"<-quantity---------result->"+res.result)
          console.log(this.productPrice,"<-productPrice---------result->"+res.result)

          //if (this.giftinfo.quantity * this.num > res.result) {
          if (this.gift_source == 1 && res.result >= this.productPrice * this.num||
              this.gift_source == 2 && res.result >= this.giftinfo.quantity ) {
            console.log(this.giftinfo.gift_source,"----------")
            if (this.giftinfo.gift_source == 2){
              this.$router.push({
                path: 'RedeemNow_jdyx',
                query: {
                  giftid: id,
                  num: this.num
                }
              })
            }else{
              this.$router.push({
                path: 'RedeemNow',
                query: {
                  giftid: id,
                  num: this.num,
                  spec_sku_id: this.check_lesson_sku_id
                }
              })
            }
          } else {
            this.$alert(this.tips + '不足', '提示', {
              showConfirmButton: false
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getRow() {
      getRow({ type: 1 }).then(res => {
        if (res.errNo === 0) {
          this.tips = res.result.name
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.red{
  color:#ee2e2e;
}
.astrict{
  line-height: 34px;
  margin-left: 20px;
}
.gift-info-box {
  width: 100%;
  margin-top: 20px;
  padding-top: 70px;
  .gift_box {
    margin: 0px auto;
    background: white;
  }
  .gift_top {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    .gift_img {
      float: left;
      width: 400px;
      height: 355px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .gift_desc {
      float: right;
      width: 600px;
      height: 100%;
      text-align: left;
      h4 {
        font-size: 22px;
        color: #333;
        font-weight: bold;
        line-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      > .desc {
        height: 25px;
        line-height: 25px;
        margin: 10px 0px 15px 0px;
        font-size: 15px;
        img {
          height: 22px;
          width: 20px;
          vertical-align: top;
        }
        span {
          margin-right: 30px;
        }
      }
      p {
        font-size: 12px;
        // height: 92px;
        line-height: 23px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        margin-bottom: 15px;
      }
    }
  }
}
/deep/.el-input__inner {
  height: 35px;
  line-height: 35px;
  text-align: center;
}
/deep/.el-button--text {
  color: #333;
}
.num_line {
  font-size: 12px;
  > div {
    display: inline-block;
    margin-right: 10px;
  }
  span {
    margin: 0 10px;
  }
}
.dh_line {
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  margin: 30px 0px;
  .ljdh {
    height: 22px;
    width: 20px;
    margin-top: -5px;
  }
}
.gift_img_title {
  position: relative;
  margin: 20px 0px;
  img {
    margin: 30px 0 20px;
    width: 100%;
    height: 2px;
  }
  div {
    height: 52px;
    width: 146px;
    line-height: 52px;
    color: #ee2e2e;
    position: absolute;
    margin-left: -73px;
    font-size: 22px;
    top: 0px;
    background: white;
    font-weight: bold;
    left: 50%;
  }
}
.gifi_img_content {
  text-align: center;
  padding-bottom: 30px;
  img {
    max-width: 100%;
    height: auto;
  }
}
/deep/.el-message-box__message p {
  text-align: center !important;
  font-size: 16px;
  margin-bottom: 10px;
}

// 左侧商品大图+轮播
.product_left{
  width:400px;
  display: flex;
  flex-direction: column;
  .product_big_img{
    width: 400px;
    height: 400px;
    margin-bottom:20px;
    img{
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  // 去除原按钮样式
  .swiper-button-prev{
    background:transparent;
  }
  .swiper-button-next{
    background:transparent;
  }
  .swiper-button-prev{
    left:-20px;
  }
  .swiper-button-next{
    right:-20px;
  }
  .swiper-button-prev,.swiper-button-next{
    margin-top: -8px;
    width:16px;
    height:16px;
  }
  .left_bottom{
    width: 400px;
  }
  .product_banner{
    position:relative;
    width: 350px;
    margin:0 auto;
    .product_swiper{
      .banner_img{
        width:65px;
        height:65px;
        box-sizing:border-box;
        margin:0 auto;
        img{
          display: block;
          width:100%;
          height:100%;
        }
      }
      .active{
        border:2px solid #ee2e2e;
      }
    }
  }
}
.product_right{
  width:700px;
  // 商品名称
  .product_name{
    font-family: PingFangSC-Heavy;
    color: #333333;
    letter-spacing: 0;
    font-weight: 500;
    margin-bottom:20px;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
  }
  .product_activity{
    // 限时活动
    .activity_box{
      width: 750px;
      height: 45px;
      padding: 18px;
      background: #EE2E2E;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .activity_left{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .clock{
          width: 22px;
          height: 22px;
          margin-right: 11px;
        }
        .activity_title{
          font-family: PingFangSC-Bold;
          font-size: 22px;
          color: #FFFFFF;
          letter-spacing: 0;
          font-weight: 500;
          height: 22px;
          line-height: 22px;
        }
      }
      .activity_right{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .activity_time{
          font-family: PingFangSC-Medium;
          font-size: 16px;
          color: #FFFFFF;
          letter-spacing: 0;
          font-weight: 500;
          margin-right: 10px;
        }
        .time{
            min-width: 24px;
            padding: 0 4px;
            height: 32px;
            background: #FFFFFF;
            border-radius: 2px;
            // font-family: PingFangSC-Bold;
            font-size: 20px;
            color: #EE2E2E;
            letter-spacing: 0;
            text-align: center;
            line-height:32px;
            font-weight: 600;
          }
          .symbol{
            font-size: 16px;
            color: #FFFFFF;
            letter-spacing: 0;
            font-weight: 500;
            margin:0 0px 0 10px;
          }
          .day{
            margin-right: 10px;
          }
      }
    }
    // 商品价格
    .price_box{
      padding:10px 20px 10px;
      display: flex;
      justify-content: space-between;
      background: url('../../assets/productDetail/price_bg.png') no-repeat;
      background-size: 100% 100%;
      .price_left{
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        .price_top{
          height: 36px;
          display: flex;
          align-items: center;
          // margin-bottom:10px;
          .title{
            font-family: PingFangSC-Bold !important;
            margin-right: 5px;
            font-size: 14px;
            color: #666666;
            font-weight: 500;
            letter-spacing: 13px;
          }
          .selling_price{
            margin-right: 15px;
            .unit{
              font-family: PingFangSC-Medium;
              font-size: 16px;
              color: #EE2E2E;
              letter-spacing: 0;
              font-weight: 500;
              margin-right: 4px;
            }
            .money{
              // font-family: PingFangSC-Semibold;
              font-size: 24px;
              color: #EE2E2E;
              letter-spacing: 0;
              font-weight: 600;
            }
          }
          .discount_price{
              // font-family: PingFangSC-Medium;
              font-size: 16px;
              color: #333333;
              letter-spacing: 0;
              font-weight: 400;
            }
          .vip{
            .vip_status{
                margin-right: 10px;
                z-index:200;
                left:17px;
                top:8px;
                display: flex;
                .is_vip{
                  border-radius: 11px 0 0 11px;
                  font-size: 12px;
                  background: rgba(0, 0, 0, 0.8);
                  height: 18px;
                  line-height: 18px;
                  text-align: center;
                  position: relative;
                  left: 14px;
                  font-weight:600;
                  color: rgba(255,255,255,0.90);
                  padding: 0 10px;
                }
                .discount{
                  color: #4A351E;
                  background-image: linear-gradient(134deg, #F8DAA6 0%, #EABD6D 100%);
                  border-radius:0 11px 11px 0;
                  font-size: 12px;
                  height: 18px;
                  line-height: 18px;
                  text-align: center;
                  padding: 0 12px 0 20px;
                  font-weight:600;
                }
              }
          }
          .renew{
            display: flex;
            align-items: center;
            font-family: PingFangSC-Medium;
            font-size: 12px;
            color: #EE2E2E;
            letter-spacing: 0;
            font-weight: 400;
            cursor:pointer;
            img{
              margin-left: 5px;
            }
          }
        }
        .price_bottom{
          display: flex;
          margin-top:10px;
          align-items: center;
          .title{
            font-family: PingFangSC-Bold !important;
            margin-right: 10px;
            font-size: 14px;
            color: #666666;
            font-weight: 500;
            letter-spacing: 8px;
            flex-shrink: 0;
          }
          .vip_title{
            margin-right: 0;
          }
          .vip_price{
            letter-spacing: 0;
            margin-right:26px;
          }
          .promotion_price{
            // font-family: PingFangSC-Medium;
            font-size: 16px;
            color: #666666;
            letter-spacing: 0;
            font-weight: 400;
          }

          // 折扣拼团样式
          .discount_price{
              // font-family: PingFangSC-Medium;
              font-size: 16px;
              color: #333333;
              letter-spacing: 0;
              font-weight: 400;
              margin-left:5px;
            }
          .vip_discount_price{
              margin-left:10px;
          }
          // 拼团
          .go_group{
            flex-shrink: 0;
            display:flex;
            align-items: center;
            padding:2px 10px;
            background: #ee2e2e;
            margin-left:5px;
            font-family: PingFangSC-Medium;
            font-size: 14px;
            color: #FFFFFF;
            letter-spacing: 0.88px;
            font-weight: 500;
            border-radius: 20px;
            cursor:pointer;
            img{
              margin-right:4px;
            }
          }
          .vip{
            flex-shrink: 0;
            .vip_status{
                margin-right: 5px;
                z-index:200;
                left:17px;
                top:8px;
                display: flex;
                .is_vip{
                  border-radius: 11px 0 0 11px;
                  font-size: 12px;
                  background: rgba(0, 0, 0, 0.8);
                  height: 18px;
                  line-height: 18px;
                  text-align: center;
                  position: relative;
                  left: 14px;
                  font-weight:600;
                  color: rgba(255,255,255,0.90);
                  padding: 0 10px;
                }
                .discount{
                  color: #4A351E;
                  background-image: linear-gradient(134deg, #F8DAA6 0%, #EABD6D 100%);
                  border-radius:0 11px 11px 0;
                  font-size: 12px;
                  height: 18px;
                  line-height: 18px;
                  text-align: center;
                  padding: 0 12px 0 20px;
                  font-weight:600;
                }
              }
          }
          .renew{
            flex-shrink: 0;
            display: flex;
            align-items: center;
            font-family: PingFangSC-Medium;
            font-size: 12px;
            color: #EE2E2E;
            letter-spacing: 0;
            font-weight: 400;
            cursor:pointer;
            img{
              margin-left: 5px;
            }
          }
        }
      }
      .has_discount{
        .selling_price{
            margin-right: 15px;
            font-family: PingFangSC-Medium;
            font-size: 16px;
            color: #666666;
            letter-spacing: 0;
            font-weight: 400;
          }
          .unit{
              font-family: PingFangSC-Medium;
              font-size: 18px;
              color: #EE2E2E;
              letter-spacing: 0;
              font-weight: 500;
              margin-right: 4px;
            }
            .money{
              // font-family: PingFangSC-Semibold;
              font-size: 24px;
              color: #EE2E2E;
              letter-spacing: 0;
              font-weight: 600;
            }
      }
      .price_right{
        flex-shrink: 0;
        position:relative;
        .line{
          width: 1px;
          height: 38px;
          border-left: 2px solid rgba(199,199,199,1);
          position:absolute;
          left: -12px;
          top:12px;
        }
        .bug_count{
          height: 36px;
          line-height: 36px;
        }
        .evaluate{
          font-family: PingFang-SC-Medium;
          font-size: 14px;
          color: #999999;
          letter-spacing: 0;
          text-align: right;
          font-weight: 400;
          margin-top: 15px;
        }
      }
    }
  }
  .product_info{
    padding:20px;
    // 商品规格
    .specification{
      display: flex;
      justify-content: flex-start;
      margin-bottom:10px;
      .title{
        font-family: PingFangSC-Bold !important;
        margin-right: -5px;
        font-size: 14px;
        color: #666666;
        font-weight: 500;
        letter-spacing: 30px;
        flex-shrink: 0;
        line-height: 28px;
      }
      .specification_list{
        display: flex;
        flex-wrap: wrap;
        .specification_item{
          position: relative;
          .product_status{
            position:absolute;
            background: #C7C7C7;
            padding:0 4px;
            border-radius: 1px;
            color:#fff;
            top: -11px;
            right: 21px;
            font-size:12px;
          }
          .item{
            display: flex;
            align-items: center;
            height: 30px;
            background: #F3F3F3;
            border-radius: 2px;
            font-family: PingFangSC-Medium;
            font-size: 14px;
            color: #333333;
            letter-spacing: 0;
            font-weight: 400;
            padding:2px 10px 2px 10px;
            border: 1px solid #F3F3F3;
            margin-right: 20px;
            margin-bottom:15px;
            cursor:pointer;
            img{
              display: block;
              width: 26px;
              height: 26px;
              margin-right:4px;
            }
          }
          .on{
            background: #FFFFFF;
            border: 1px solid #ee2e2e;
            color:#ee2e2e;
          }
          .disable{
            background: #F5F7FA;
            border: 1px solid #E4E7ED;
            color: #333333;
            cursor: not-allowed;
          }
        }

      }

    }
    // 商品套餐
    .package{
      display: flex;
      justify-content: flex-start;
      margin-bottom:10px;
      .title{
        font-family: PingFangSC-Bold !important;
        margin-right: -5px;
        font-size: 14px;
        color: #666666;
        font-weight: 500;
        letter-spacing: 30px;
        flex-shrink: 0;
        line-height: 28px;
      }
      .package_list{
        display: flex;
        flex-wrap: wrap;
        .package_item{
          position: relative;
          .package_status{
            position:absolute;
            background: #C7C7C7;
            padding:0 4px;
            border-radius: 1px;
            color:#fff;
            top: -11px;
            right: 21px;
            font-size:12px;
          }
          .item{
            display: flex;
            align-items: center;
            height: 30px;
            background: #F3F3F3;
            border-radius: 2px;
            font-family: PingFangSC-Medium;
            font-size: 14px;
            color: #333333;
            letter-spacing: 0;
            font-weight: 400;
            // padding:2px 10px 2px 2px;
            padding:2px;
            border: 1px solid #F3F3F3;
            margin-right: 20px;
            margin-bottom:15px;
            cursor:pointer;
            img{
              display: block;
              width: 26px;
              height: 26px;
              margin-right:4px;
            }
          }
          .on{
            background: #FFFFFF;
            border: 1px solid #ee2e2e;
            color:#ee2e2e;
          }
        }

      }

    }
    // 商品数量
    .product_num{
      display: flex;
      .title{
        font-family: PingFangSC-Bold !important;
        margin-right: -5px;
        font-size: 14px;
        color: #666666;
        font-weight: 500;
        letter-spacing: 30px;
        flex-shrink: 0;
        line-height: 28px;
      }
      /deep/ .el-input__inner{
        background: #F3F3F3;
      }
      .el-input-number--small {
          width: 110px;
          line-height: 30px;
      }
      /deep/ .el-input-number{
        outline: none;
      }
      /deep/ .el-input__inner{
        border:0;
      }
      /deep/ .el-input-number__decrease {
          font-weight: 600;
          left: 1px;
          // border-radius: 4px;
          border-right: 1px solid #fff;
          background: #F3F3F3;
      }
      /deep/ .el-input-number__increase {
          font-weight: 600;
          right: 1px;
          // border-radius: 4px;
          border-left: 1px solid #fff;
          background: #F3F3F3;
      }
    }
    // 立即购买
    .buy_box{
      display: flex;
      align-items: center;
      margin-top:40px;
      .line{
        width: 0;
        height: 47px;
        border-left:2px solid #ccc;
        margin:0 30px;
      }
      .collectBox{
        cursor: pointer;
        .collect_icon{
          margin-right: 10px;
        }
      }
    }
    .go_buy{
      width: 145px;
      height: 47px;
      text-align: center;
      line-height:47px;
      background-image: linear-gradient(145deg, #FF5B5B 0%, #EE2E2E 100%);
      border-radius: 100px;
      font-family: PingFangSC-Bold;
      font-size: 18px;
      color: #FFFFFF;
      letter-spacing: 1.12px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
</style>
